<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id!=null">Edit {{title}}</b>
      <b v-else>Create {{title}}</b>
    </span>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          v-model="territory.selected"
          :options="territory.options"
          placeholder="Type to search"
          label="name"
          track-by="code"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-show="errors.has('Territory')"
        >{{ errors.first('Territory') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Warehouse</label>
        <multiselect
          v-model="warehouse.selected"
          :options="warehouse.options"
          placeholder="Type to search"
          label="warehouse_name"
          track-by="warehouse_code"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span
                class="option__title"
              >{{ props.option.warehouse_code }} {{ props.option.warehouse_name }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span
                class="option__title"
              >{{ props.option.warehouse_code }} {{ props.option.warehouse_name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-show="errors.has('Warehouse')"
        >{{ errors.first('Warehouse') }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Default</label>
        <vs-switch
          color="dark"
          icon-pack="feather"
          vs-icon-on="icon-check-circle"
          vs-icon-off="icon-slash"
          v-model="data.isDefault"
        >
          <span slot="on">Yes</span>
          <span slot="off">No</span>
        </vs-switch>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose">Close</vs-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number
    },
    baseUrl: {
      type: String
    },
    title: {
      type: String
    }
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          territoryId: null,
          warehouseId: null,
          isDefault: true
        },
        territory: {
          selected: null,
          options: []
        },
        warehouse: {
          selected: null,
          options: []
        }
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (!this.data.warehouseId) {
          this.errors.add({
            field: "Warehouse",
            msg: "The Warehouse field is required"
          });
          result = false;
        }
        if (!this.data.territoryId) {
          this.errors.add({
            field: "Territory",
            msg: "The Territory field is required"
          });
          result = false;
        }
        if (result) {
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      let is_default = "";
      if (this.data.isDefault) {
        is_default = "1";
      } else {
        is_default = "0";
      }
      return {
        territory_id: this.data.territoryId,
        warehouse_id: this.data.warehouseId,
        is_default: is_default
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Territory Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.id, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Territory Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.id) {
        this.$http
          .get(this.baseUrl + "/" + this.id)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.territoryId = resp.data.territory_id;
              this.data.warehouseId = resp.data.warehouse_id;
              if (resp.data.is_default == "1") {
                this.data.isDefault = true;
              } else {
                this.data.isDefault = false;
              }

              this.$http
                .get("/api/v1/master/territory/" + this.data.territoryId)
                .then(resp => {
                  this.$vs.loading.close();
                  if (resp.code == 200) {
                    this.territory.selected = resp.data;
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle"
                    });
                  }
                })
                .catch(error => {
                  this.$vs.loading.close();
                  console.log(error);
                });

              this.$http
                .get(this.baseUrl + "/warehouse/" + this.data.warehouseId)
                .then(resp => {
                  this.$vs.loading.close();
                  if (resp.code == 200) {
                    this.warehouse.selected = resp.data;
                  } else {
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle"
                    });
                  }
                })
                .catch(error => {
                  this.$vs.loading.close();
                  console.log(error);
                });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    getAllDataWarehouse() {
      this.$vs.loading();
      this.$http.get(this.baseUrl + "/warehouse").then(resp => {
        if (resp.status == "success") {
          this.warehouse.options = resp.data.records;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          console.log(resp.data);
        }
      });
    },
    getAllDataTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    }
  },
  mounted() {
    this.getAllDataWarehouse();
    this.getAllDataTerritory();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
      this.getAllDataWarehouse();
      this.getAllDataTerritory();
    },
    "warehouse.selected": function(val) {
      if (val) {
        this.errors.clear();
        this.data.warehouseId = val.id;
      } else {
        this.data.warehouseId = null;
      }
    },
    "territory.selected": function(val) {
      console.log("val", val)
      if (val) {
        this.errors.clear();
        this.data.territoryId = val.id ? val.id : val.ID;
      } else {
        this.data.territoryId = null;
      }
    }
  }
};
</script>
