<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="territoryAreaId != null">Edit Sales Area</b>
      <b v-else>Create Sales Area</b>
    </span>
    <div class="vx-row mb-6" v-if="territoryAreaId != null">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="Code" v-model="data.code" />
      </div>
    </div>
    <div class="vx-row mb-6" v-else>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          name="Code"
          label="Code"
          v-model="data.code"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Code')">{{
          errors.first("Code")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Description"
          name="Description"
          v-model="data.description"
        />
      </div>
    </div>
    <address-component
      :data="data.address"
      @data="setDataAddress"
    ></address-component>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import AddressComponent from "@/components/master/Address";

export default {
  components: {
    AddressComponent,
  },
  props: {
    territoryAreaId: {
      type: Number,
    },
    baseUrl: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          code: "",
          name: "",
          description: "",
          address: {
            address: "",
            postalCode: "",
            countryName: "",
            provinceName: "",
            cityName: "",
            districtName: "",
            subdistrictName: "",
          },
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.territoryAreaId) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        type: "Sales",
        code: this.data.code,
        name: this.data.name,
        description: this.data.description,
        country: this.data.address.countryName,
        province: this.data.address.provinceName,
        city: this.data.address.cityName,
        district: this.data.address.districtName,
        sub_district: this.data.address.subdistrictName,
        address: this.data.address.address,
        postal_code: this.data.address.postalCode,
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New Customer Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.territoryAreaId, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.territoryAreaId) {
        this.$http
          .get(this.baseUrl + "/" + this.territoryAreaId)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.code = resp.data.code;
              this.data.name = resp.data.name;
              this.data.description = resp.data.description;
              this.data.address.address = resp.data.address;
              this.data.address.postal_code = resp.data.postal_code;
              this.data.address.countryName = resp.data.country;
              this.data.address.provinceName = resp.data.province;
              this.data.address.cityName = resp.data.city;
              this.data.address.districtName = resp.data.district;
              this.data.address.subdistrictName = resp.data.sub_district;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    setDataAddress(val) {
      this.data.address = val;
    },
  },
  mounted() {
    if (this.territoryAreaId) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    territoryAreaId(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
